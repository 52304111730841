import { Identifier, Record } from './types';

const API_URI = process.env.NEXT_PUBLIC_API_URI;

export const extractIdFromURI = (URI: string) => {
    const mat = URI.split('/');
    return mat[mat.length - 1];
};

export const getResourceURIFROMAnotherURI = (anotherURI: string, id: Identifier, resource: string) =>
    // @ts-ignore
    `${anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)')[1]}/${resource}/${id}`;

export const uniqueItemInArray = (items: Record[]): any => {
    const obj: { [key: string]: Record } = {};
    items.forEach((item) => {
        obj[item.id] = item;
    });

    return Object.values(obj);
};

export const getResourceURIFROMAnotherURIWithoutId = (anotherURI: string, resource: string) =>
    // @ts-ignore
    `${anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)')[1]}/${resource}`;

export const composeUri = (resource: string, id?: string) =>
    // @ts-ignore
    `${API_URI}/${resource}${id ? `/${id}` : ''}`;
