import { getAxiosFn } from '../components/utils';
import { CustomUseQueryOptions } from './types';
import qs from 'qs';

const CORE_API_URI = process.env.NEXT_PUBLIC_CORE_API_URI;
const FILE_SYSTEM_MAPPER_ENTRYPOINT = process.env.NEXT_PUBLIC_FILE_SYSTEM_MAPPER_ENTRYPOINT;
const ICONS_ORIGIN_MAPPER = `${process.env.NEXT_PUBLIC_ICONS_URL}/json/mapper.json`;

const makeGetOne = (
    resource: string,
    params: {
        id: string;
        filters?: { [key: string]: any };
    },
    options: CustomUseQueryOptions & { queryClient: any; notify: any; translate: any }
) => {
    const { queryClient, notify, translate } = options;

    const finalBasePath = options?.basePath
        ? options.basePath === 'fm'
            ? `${FILE_SYSTEM_MAPPER_ENTRYPOINT}/`
            : ICONS_ORIGIN_MAPPER
        : CORE_API_URI;

    const finalResource = options?.responseFormat && options?.responseFormat === 'json' ? `${resource}.json` : resource;

    const finalFilters = qs.stringify({
        ...('filters' in params ? params.filters : {}),
    });

    const finalUrl = `${finalBasePath}${params.id}?${finalFilters}`;

    const keyToFind = options && 'keyToFind' in options ? options.keyToFind : 'keyToFind';

    queryClient
        .fetchQuery([keyToFind, 'getOne', finalResource, finalFilters], () =>
            getAxiosFn(finalUrl, options).then(({ data }) => data)
        )
        .then((data: any) => {
            if (options.onSuccess) {
                options.onSuccess(data);
            }
        })
        .catch((error: any) => {
            console.log('makeGetListError:', error);
            notify(options?.errorMessage ? translate(options.errorMessage) : error.message, { type: 'error' });
            if (options.onError) {
                options.onError(error);
            }
        });
};

export default makeGetOne;
