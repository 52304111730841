import React from 'react';
import { useStore } from '../../../store';
import { LOGIN_DIALOG_STATE_STORE_KEY, LOGOUT_DIALOG_STATE_STORE_KEY } from '../../../services/utils/CONST';

const withLoginHandler = () => (Component: any) =>
    function WithLogin(props: any) {
        const [, setLoginDialogState] = useStore<boolean>(LOGIN_DIALOG_STATE_STORE_KEY, false);
        const [, setLogoutDialogState] = useStore<boolean>(LOGOUT_DIALOG_STATE_STORE_KEY, false);

        const openLoginDialog = () => {
            setLoginDialogState(true);
        };

        const closeLoginDialog = () => {
            setLoginDialogState(false);
        };

        const openLogoutDialog = () => {
            setLogoutDialogState(true);
        };

        const closeLogoutDialog = () => {
            setLogoutDialogState(false);
        };

        return (
            <Component
                {...props}
                openLoginDialog={openLoginDialog}
                closeLoginDialog={closeLoginDialog}
                openLogoutDialog={openLogoutDialog}
                closeLogoutDialog={closeLogoutDialog}
            />
        );
    };

export default withLoginHandler;
