import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Snackbar } from '@mui/material';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert, { alertClasses } from '@mui/material/Alert';
import { useNotificationContext } from './useNotificationContext';

const CustomAlert = styled(Alert)({
    width: '100%',
    [`& .${alertClasses.action}`]: {
        alignItems: 'center',
        paddingTop: 0,
    },
});

export const Notification = () => {
    const { notifications, takeNotification } = useNotificationContext();

    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState<any>(undefined);

    useEffect(() => {
        if (notifications.length && !messageInfo) {
            setMessageInfo(takeNotification());
            setOpen(true);
        } else if (notifications.length && messageInfo && open) {
            setOpen(false);
        }
    }, [notifications, messageInfo, open, takeNotification]);

    const handleExited = useCallback(() => {
        setMessageInfo(undefined);
    }, [messageInfo]);

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    if (!messageInfo) return null;

    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleRequestClose}
            TransitionComponent={Fade}
            data-cy='notification'
            TransitionProps={{ onExited: handleExited }}
        >
            <CustomAlert
                variant={'filled'}
                onClose={handleRequestClose}
                severity={messageInfo.type}
                action={
                    <div>
                        <IconButton
                            size='small'
                            color='inherit'
                            onClick={handleRequestClose}
                            data-cy='closeNotificationButton'
                        >
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </div>
                }
                icon={false}
            >
                {messageInfo.message}
            </CustomAlert>
        </Snackbar>
    );
};
