import { ThemeProvider } from '@mui/material/styles';
import 'external-svg-loader';
import { NextIntlProvider } from 'next-intl';
import React from 'react';
import LoginDialog from '../components/dialogs/login-dialog/login-dialog';
import LogoutDialog from '../components/dialogs/logout-dialog/logout-dialog';
import HeadConf from '../components/head-conf/head-conf';
import { capitalizeString } from '../components/utils';
import { Company } from '../services/utils/types';
import '../styles/globals.css';
import mainTheme from '../themes/main-theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { memoryStore, StoreContextProvider, useStore } from '../store';
import { NotificationContextProvider } from '../notification';
import { Notification } from '../notification/Notification';
import {
    COMPANY_STORE_KEY,
    LOGIN_DIALOG_STATE_STORE_KEY,
    LOGOUT_DIALOG_STATE_STORE_KEY,
} from '../services/utils/CONST';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 5000,
            retry: false,
        },
    },
});

const defaultStore = memoryStore();

const MyApp = ({ Component, pageProps }: any) => {
    const [company] = useStore<Company | null>(COMPANY_STORE_KEY, null);
    const [loginDialogState] = useStore<boolean>(LOGIN_DIALOG_STATE_STORE_KEY, false);
    const [logoutDialogState] = useStore<boolean>(LOGOUT_DIALOG_STATE_STORE_KEY, false);

    return (
        <NotificationContextProvider>
            <ThemeProvider theme={mainTheme}>
                <NextIntlProvider messages={pageProps.messages} locale={'en'}>
                    <QueryClientProvider client={queryClient}>
                        <HeadConf title={company ? capitalizeString(company.name) : '360SmartConnect'} />
                        {loginDialogState && <LoginDialog />}
                        {logoutDialogState && <LogoutDialog />}
                        <Notification />
                        <Component {...pageProps} />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </NextIntlProvider>
            </ThemeProvider>
        </NotificationContextProvider>
    );
};

const MyAppContainer = (props: any) => (
    <StoreContextProvider value={defaultStore}>
        <MyApp {...props} />
    </StoreContextProvider>
);

export default MyAppContainer;
