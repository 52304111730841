import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    open?: boolean;
}

const LoadingBackdrop = ({ open = true }: Props) =>
    open ? (
        <Backdrop open={open} sx={{ zIndex: 300 }} data-cy='loadingBackdrop'>
            <CircularProgress />
        </Backdrop>
    ) : null;

export default LoadingBackdrop;
