const FINGER_TOKEN_PREFIX = process.env.NEXT_PUBLIC_FINGER_TOKEN_PREFIX;
const MC_AUTO_AUTH_TOKEN_PREFIX = process.env.NEXT_PUBLIC_MC_AUTO_AUTH_TOKEN_PREFIX;
const FINGER_UNIQUE_VALUE = process.env.NEXT_PUBLIC_FINGER_UNIQUE_VALUE;

export const AFTER_LOGIN_REDIRECT_TO = 'after_login_redirect_to';
export const AFTER_ABOUT_REDIRECT_TO = 'after_about_redirect_to';

export const TYPE_NOTES_PATH = 'type_notes';
export const TYPE_NOTES_NAME = 'MEMOTAGPRO';
export const MC_NOTES_PATH = 'm_c_notes';
// export const UPLOAD_IMAGES = 'uploads/upload_file';
// export const UPLOAD_IMAGES = 'medias';
export const UPLOAD_MEDIAS = 'medias';
export const TYPE_NOTES = 'type_notes';
export const AVATAR_TRACEABILITY_NOTES_TYPE_MCNOTEHISTORY = 'MCNoteHistory';
export const AVATAR_TRACEABILITY_NOTES = 'avatar_traceability_notes';
export const METADATA_AVATAR_TYPES = 'metadata_avatar_types';
export const METADATA_AVATAR_TYPE_PROPERTIES = 'metadata_avatar_type_properties';
export const DEFAULT_START_CONTROL_PROCESSES = 'default_start_control_processes';
export const MCS_CROSS_STOCK = 'm_cs/cross-stock';
export const TEMPORARY_YOURLS = 'temporary_yourls';
export const AVATAR_TRACEABILITY = 'traceability/current_referencing';
export const AVATARS = 'avatars';
export const MCS = 'm_cs/cross-stock';
export const YOURLS = 'yourls';
export const EVENT_TYPES = 'ticketing/event_types_allowed_in_places';
export const TICKETING_EVENT_INSTRUCTION_HELPS = 'ticketing/event_instruction_helps';
export const PLACES_OR_EVENTS = 'ticketing/places_or_events';
export const PLACES_OR_EVENTS_PAIRING = 'ticketing/place_or_events/pairing';
export const EVENTS_COUNT = 'event/count';
export const AUTO_APP_INSTANCES = 'create_auto_auth_app_instances';
export const AVATAR_TEMPLATES = 'avatar_templates';
export const IMAGES = 'image';
export const FILES = 'files';
export const FILE = 'file';
export const MEDIA = 'media';
export const TRACEABILITY_TRACKING = 'traceability/tracking';
export const SIMPLE_ZONES = 'simple_zones';
export const ZONE_CONFIGS = 'zone_configs';
export const USER_CONNECTED = 'user-connected';
export const GROUP_AVATARS = 'group_avatars';
export const ADD_GROUP = 'add_group';
export const REMOVE_GROUP = 'remove_group';
export const METADATA_LABELS = 'metadata_labels';
export const COMPANIES = 'companies';
export const TOKEN_WITH_ROLES = 'token-connected';
export const AVATAR_PROPERTIES = 'avatar_properties';
export const SEND_EMAILS = 'send_emails';
export const NOTE_HISTORY = 'm_c_note_histories';
export const TRACEABILITY_HISTORY = 'traceability_histories';
export const GROUP_METADATA_AVATAR_TYPES = 'group_metadata_avatar_types';
export const EVENT_USERS = 'ticketing/event_users';
export const USERS = 'users';

export const DATA_SOURCE_FINGER = 'MemoTAGPRO';
export const DATA_SOURCE_BACKOFFICE = 'backoffice';

export const InitialValuesRoute = '/initial-values';

export const UNIQUE_IDENTIFIER_BY_FINGER = FINGER_UNIQUE_VALUE;
export const METADATA_AVATAR_TYPE_EQUALPROCESSDEFNITION_MAPPER_MEMOTAG_DATA = 'MEMOTAGPRO';

export const MEDIA_FILE_TYPE = 'FILE';
export const MEDIA_IMAGE_TYPE = 'IMAGE';
export const MEDIA_LINK_TYPE = 'LINK';
export const MEDIA_AUDIO_TYPE = 'AUDIO';
export const RESOURCE_SUPPORTED_MEDIAS = 'avatar';
export const UPLOAD_MEDIA = 'medias';

export const NOTE_MEDIA_FILE = 'File';
export const NOTE_MEDIA_AUDIO = 'Audio';
export const NOTE_MEDIA_IMAGE = 'Image';

export const AUTHENTICATION_TYPE = 'Bearer';

export const MAIN_PAGE = '/main';
export const INDEX_PAGE = '/';
export const ROOT = '/';
export const CREATE_AVATAR_PAGE = '/create-avatar';
export const LOGIN_PAGE = '/login';
export const LEVEL_PAGE = '/level';
export const LOADING_DATA_PAGE = '/loading-data';
export const OUT_OF_MAINTENANCE_PAGE = '/out-of-maintenance';
export const INCIDENT_PAGE = '/incident';
export const TO_TAKE_INTO_ACCOUNT_PAGE = '/to-take-into-account';
export const CHECKING_PAGE = '/checking';
export const CHECKING_PRINT_PAGE = '/checking-print';
export const PRINT_PAGE = '/print';
export const FINANCE_PAGE = '/finance';
export const REPORTER_EVALUATION_PAGE = '/reporter-evaluation';
export const ABOUT_PAGE = '/about';

export const AVATAR_CREATION_TYOURLS_STORAGE = '/avatar-creation-tyourls-storage';

//Modes

export const TOKEN_PARTS_PREFIX = `${FINGER_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_SIZE = `${FINGER_TOKEN_PREFIX}-tokenPartsSize`;

export const TOKEN_PARTS_MC_AUTO_AUTH_PREFIX = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_MC_AUTO_AUTH_SIZE = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPartsSize`;
export const PAIRING_MODE = 'PAIRING_MODE';
export const SC360_PLANIFICATION_SERVICE = 'SC360_PLANIFICATION_SERVICE';
export const SC360_PAIRING_FROM_LINK = 'SC360_PAIRING_FROM_LINK';

export const INTEGRATION_ID = 'INTEGRATION_ID';
export const METADATAAVATARTYPEURI = 'METADATAAVATARTYPEURI';
export const PRODUCT_INFORMATIONS = 'PRODUCT_INFORMATIONS';
export const METADATALABEL_BUSINESSURI = 'METADATALABEL_BUSINESSURI';
export const METADATALABEL_BUSINESS = 'METADATALABEL_BUSINESS';
export const METADATALABEL_CONSTRUCTION_SITE = 'METADATALABEL_CONSTRUCTION_SITE';
export const METADATALABEL_CONSTRUCTION_SITEURI = 'METADATALABEL_CONSTRUCTION_SITEURI';
export const METADATALABEL_FACTORYURI = 'METADATALABEL_FACTORYURI';
export const METADATALABEL_FACTORY = 'METADATALABEL_FACTORY';
export const AVATAR_CLIENT_ID = 'AVATAR_CLIENT_ID';
export const AVATAR_CLIENT_NAME = 'AVATAR_CLIENT_NAME';
export const VALIDATION_TIME = 'VALIDATION_TIME';
export const LINKED_SOURCE_INFORMATION = 'LINKED_SOURCE_INFORMATION';
export const RESOURCE_CLIENT_ID = 'RESOURCE_CLIENT_ID';
export const CLASS_MAPPER = 'CLASS_MAPPER';
export const RAW_PROPERTIES = 'RAW_PROPERTIES';

export const CORE_INTEGRATION_CLASS_MAPPER_SUPPLEMENTS =
    'Platform360\\Core\\Domain\\Avatar\\AvatarClassMapperSupplements\\CoreIntegrationClassMapperSupplements';
export const EXTERNAL_SYSTEM_INTEGRATION_CLASS_MAPPER_SUPPLEMENTS =
    'Platform360\\Core\\Domain\\Avatar\\AvatarClassMapperSupplements\\ExternalSystemIntegration';

export const TOKEN_MAX_PARTS_SIZE = 2000;
// DEPRECATED
export const LOGIN_ANONYMOUS = 'optimized_token_semi_anonymous_easy_login_check';

export const LOGIN_OPTIMIZED_TOKEN = 'optimized_token_login_check';

export const TRACEABILITY_LINEAL = 'lineal';
export const TRACEABILITY_DECLARATIVE = 'declarative';
export const TRACEABILITY_DECLARATIVE_FORWARD = 'declarative_forward';
export const FINISH_PRODUCE_TOKEN = 'FINISH_PRODUCE_TOKEN';
export const ROLE_FILE_MANAGER_V1 = 'ROLE_FILE_MANAGER_V1';
export const ROLE_CORE_SC_PUBLISH_TOKEN = 'ROLE_CORE_SC_PUBLISH_TOKEN';

/*File Manager Cloud Roles**/
export const ROLE_FILE_MANAGER_CLOUD_READ = 'ROLE_FM_CLOUD_READ';
export const ROLE_FILE_MANAGER_CLOUD_READ_DOWNLOAD = 'ROLE_FM_CLOUD_READ_DOWNLOAD';
export const ROLE_FILE_MANAGER_CLOUD_WRITE = 'ROLE_FM_CLOUD_WRITE';

export const ROLE_CREATE_NFC_BADGE = 'ROLE_CREATE_NFC_BADGE';
export const ROLE_UPDATE_AVATAR_PROPERTY = 'ROLE_UPDATE_AVATAR_PROPERTY';
export const ROLE_CREATE_AVATAR_PROPERTY = 'ROLE_CREATE_AVATAR_PROPERTY';
export const ROLE_READ_AVATAR_PROPERTY = 'ROLE_READ_AVATAR_PROPERTY';

export const PROPERTY = 'Propriété';
export const VALUE = 'Valeur';

export const AUTO_ACTIONS = 'autoactions';
export const AUTO_ACTIONS_VERSION_1 = 'change-next-node:v1';
export const AUTO_ACTIONS_RESTART_VERSION_1 = 'change-next-node-restart:v1';
export const AUTO_ACTIONS_CHANGE_TIME = 'AUTO_ACTIONS_CHANGE_TIME';

export const NOTE_TYPE = 'note';
export const TRACEABILITY_TYPE = 'traceability';

export const MAINTENANCE_TAG = 'maintenance';
export const EMPTYING_TAG = 'emptying';
export const SPANC_TAG = 'spanc';
export const AIR_TABLE_TOKEN_IDENTIFIER = 'airtable-token';

export const INITIAL_MESSAGE_KEY = 'INITIAL_MESSAGE';

export const NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING = 'NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING';
export const NODE_TYPE_TICKETING_START = 'NODE_TYPE_TICKETING_START';
export const NODE_TYPE_TICKETING_REPAIRING = 'NODE_TYPE_TICKETING_REPAIRING';
export const NODE_TYPE_TICKETING_REPAIRED = 'NODE_TYPE_TICKETING_REPAIRED';
export const NODE_TYPE_END = 'NODE_TYPE_END';
export const NODE_TYPE_PAUSE_COUNTER_TIME = 'NODE_TYPE_PAUSE_COUNTER_TIME';
export const NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT';
export const NODE_TYPE_TICKETING_WAITING_TOOK_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_WAITING_TOOK_INTO_ACCOUNT';
export const NODE_TYPE_TICKETING_OPENED = 'NODE_TYPE_TICKETING_OPENED';
export const NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT';
export const NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT';
export const NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER = 'NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER';
export const NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT = 'NODE_TYPE_TICKETING_REFUSED_TAKE_INTO_ACCOUNT';

export const NODES_FILTERS = [
    NODE_TYPE_TICKETING_OPENED,
    NODE_TYPE_TICKETING_REPAIRING,
    NODE_TYPE_PAUSE_COUNTER_TIME,
    NODE_TYPE_TICKETING_TOOK_INTO_ACCOUNT,
    NODE_TYPE_TICKETING_JUST_BEFORE_START_REPAIRING,
    NODE_TYPE_TICKETING_TAKE_INTO_ACCOUNT,
    NODE_TYPE_TICKETING_WAITING_TAKE_INTO_ACCOUNT,
    NODE_TYPE_WAITING_ASSIGN_SERVICE_PROVIDER,
];

export const LEVELS_SERIALIZATION_GROUPS = [
    'read_meta',
    'avatar_files',
    'read_avatar_parent',
    'read_meta_files',
    'read_avatar_parent',
    'link',
];

export const INCIDENTS_SERIALIZATION_GROUPS = [
    'read_meta',
    'read_meta_group',
    'read_avatar_parent',
    'read_avatar_current_referencing',
    'read_avatar_current_referencing_nodes',
    'avatar_files',
    'read_avatar_root',
    'read_mc',
    'link',
    'read_yourls',
    'read_simple_company',
    'read_meta_group_meta',
];

export const TAG_TICKETING_LOGO = 'ticketing_logo';
export const INCIDENT_KEY = 'ticketing.type.event';
export const LEVEL_KEY = 'ticketing.type.level';

export const QUOTE_TAG = 'finance:quote';
export const INVOICE_TAG = 'finance:invoice';
export const USERNAME_TAG = 'finance:username';
export const CODEPIN_TAG = 'finance:event_codepin';
export const FINANCE_TAG = 'finance';

export const CHECK_QRCODE_MODE = 'check-qrcode-01.*.*';
export const TAKE_INTO_ACCOUNT_MODE = 'take-into-account.*.*';
export const PRINT_MODE = 'print.*.*';
export const FINANCE_MODE = 'finance.*.*';
export const REPORTER_EVALUATION_MODE = 'reporter_evaluation.*.*';
export const LOCAL_STORAGE_DEFAULT_CAMERA_DEVICE = 'default_camera_device';
export const ENVIRONMENT_CAMERA_DEVICE = 'environment';
export const USER_CAMERA_DEVICE = 'user';

export const ROLE_DELETE_AVATAR = 'ROLE_DELETE_AVATAR';
export const ROLE_TICKETING_VIEW_EDIT_STRUCTURE = 'ROLE_TICKETING_VIEW_EDIT_STRUCTURE';
export const ROLE_TICKETING_CREATE_PLACE_OR_EVENT = 'ROLE_TICKETING_CREATE_PLACE_OR_EVENT';
export const ROLE_READ_OBJECT_TYPE = 'ROLE_READ_OBJECT_TYPE';
export const ROLE_READ_GROUP_OBJECT_TYPE = 'ROLE_READ_GROUP_OBJECT_TYPE';
export const ROLE_CREATE_OBJECT_TYPE = 'ROLE_CREATE_OBJECT_TYPE';
export const ROLE_CREATE_GROUP_OBJECT_TYPE = 'ROLE_CREATE_GROUP_OBJECT_TYPE';
export const ROLE_UPDATE_AVATAR_ADD_MC = 'ROLE_UPDATE_AVATAR_ADD_MC';
export const ROLE_UPDATE_AVATAR = 'ROLE_UPDATE_AVATAR';
export const ROLE_READ_COMPANY = 'ROLE_READ_COMPANY';
export const ROLE_UPDATE_MC = 'ROLE_UPDATE_MC';

export const EVOLIS_FACTURATION_ID = 'rikutec-001';

export const EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT_PROPERTY = 'EVENT_TYPE_CUSTOM_PROCESS_FORCE_COMMENT';
export const EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE_PROPERTY = 'EVENT_TYPE_CUSTOM_PROCESS_FORCE_IMAGE';

export const USER_SERVICE_PROVIDER_TAG = 'ticketing_service_provider';
export const USER_SERVICE_PROVIDER_UNDER_CONTRACT_TAG = 'ticketing_service_provider_under_contract';
export const TICKETING_WHEN_MULTIPLE_EVENTS_TITLE_SHOULD_BE_OVERWRITE =
    'ticketing_when_multiple_events_title_should_be_overwrite';
export const IS_UNDER_CONTRACT = 'is_under_contract';

export const SHOW_FILE_SYSTEM_MAPPER_PROPERTY = 'ticketing.file_system.enabled';
export const CUSTOM_FIELDS_ICON_SVG_COLLECTION = 'icon-svg-collection';
export const CUSTOM_FIELDS_ICON_FILE_SYSTEM_MAPPER = 'icon-file-system-mapper';
export const MEDIA_LINKS = 'media_links';
export const MEDIA_LINKS_FOR_CREATE = 'medias/link';
export const SVG_ICON_SYSTEM = 'SVG_ICON_SYSTEM';

export const TICKETING = 'ticketing';
export const NO_LOGGED_USERS_CAN_SEE_HISTORY_FIELD = `ticketing_no_logged_users_can_see_history`;
export const NO_LOGGED_USERS_CAN_SEE_CREATED_EVENTS_FIELD = `ticketing_no_logged_users_can_see_created_events`;
export const ASK_INCIDENT_CREATOR_EMAIL_FIELD = `ticketing_ask_incident_creator_email`;
export const TICKETING_ASK_CODE_PIN = `ticketing_ask_code_pin`;
export const USER_CONFIRMATION_AMOUNT_FIELD = 'user_confirmation_amount';
export const EMAILS_TO_NOTIFY_RESOLUTION_FIELD = 'emails_to_notify_resolution';

export const RICH_TEXT_SEND_DATA_VERSION_KEY = 'version_0.content';

export const NUMBER_INPUT_REGEXP = new RegExp(
    '[0-9]|Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Delete|Control|v'
);

export const TICKETING_QUALITY_OF_INTERVENTION_EVALUATION_PROPERTY_NAME =
    'ticketing_quality_of_intervention_evaluation';
export const TICKETING_SPEED_OF_INTERVENTION_EVALUATION_PROPERTY_NAME = 'ticketing_speed_of_intervention_evaluation';
export const TICKETING_EXPERIENCE_OF_INTERVENTION_EVALUATION_PROPERTY_NAME =
    'ticketing_experience_of_intervention_evaluation';
export const COMMENT_OF_INTERVENTION_EVALUATION_PROPERTY_NAME = 'ticketing_comment_of_intervention_evaluation';
export const TICKETING_REPORTER_EVALUATION_DEFINITION = 'ticketing_reporter_evaluation';

export const CAMERA_ID_KEY = 'camera_id';

export const ICONS_URL_STORE_KEY = 'iconsUrl';
export const AMOUNT_OF_INCIDENTS_BY_PARENT_KEY = 'amountOfIncidentsByParent';
export const AMOUNT_OF_INCIDENTS_BY_PLACE_KEY = 'amountOfIncidentsByPlace';
export const SCANNED_PARENTS_STORE_KEY = 'scannedParents';
export const AVATAR_STORE_KEY = 'avatar';
export const MC_STORE_KEY = 'mc';
export const COMPANY_STORE_KEY = 'company';
export const TOKEN_WITH_ROLES_STORE_KEY = 'tokenWithRoles';
export const TOKEN_STORE_KEY = 'token';
export const TOKEN_DATA_STORE_KEY = 'tokenData';
export const ROLES_STORE_KEY = 'roles';
export const IS_ANONYMOUS_STORE_KEY = 'isAnonymous';
export const LOGOUT_DIALOG_STATE_STORE_KEY = 'logoutDialogState';
export const LOGIN_DIALOG_STATE_STORE_KEY = 'loginDialogState';
export const AUTH_STATE_STORE_KEY = 'authState';
