import Dialog from '@mui/material/Dialog';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';
import {
    IS_ANONYMOUS_STORE_KEY,
    LOGIN_DIALOG_STATE_STORE_KEY,
    MC_STORE_KEY,
    ROLES_STORE_KEY,
    TOKEN_DATA_STORE_KEY,
    TOKEN_STORE_KEY,
    TOKEN_WITH_ROLES,
    TOKEN_WITH_ROLES_STORE_KEY,
} from '../../../services/utils/CONST';
import { parseCookies } from '../../../services/utils/cookie';
import { composeUri } from '../../../services/utils/ids';
import { decodeToken, joinTokenFromCookies } from '../../../services/utils/token';
import { MC } from '../../../services/utils/types';
import withLoginHandler from '../../hocs/with-login-handler/with-login-handler';
import { useNotify } from '../../../notification';
import LoadingBackdrop from '../../loading-backdrop/loading-backdrop';
import makeGetOne from '../../../react-query-hooks/makeGetOne';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../../store';
import axios from 'axios';

const rootAddress: any = process.env.NEXT_PUBLIC_ROOT_ADDRESS;
const loginUrl: any = process.env.NEXT_PUBLIC_LOGIN_URL;

interface Props {
    closeLoginDialog: any;
}

const LoginDialog = ({ closeLoginDialog }: Props) => {
    const tCommon = useTranslations('common');
    const [mc] = useStore<MC | null>(MC_STORE_KEY, null);
    const [loginDialogState] = useStore<boolean>(LOGIN_DIALOG_STATE_STORE_KEY, false);
    const [loadingState, setLoadingState] = useState(true);
    const [iframeUrl, setIframeUrl] = useState<any>(null);
    const [iframeState, setIframeState] = useState(true);
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [, setToken] = useStore<string | null>(TOKEN_STORE_KEY, null);
    const [, setTokenWithRoles] = useStore<string | null>(TOKEN_WITH_ROLES_STORE_KEY, null);
    const [, setTokenData] = useStore<any>(TOKEN_DATA_STORE_KEY, null);
    const [, setRoles] = useStore<string[]>(ROLES_STORE_KEY, []);
    const [, setIsAnonymous] = useStore<boolean>(IS_ANONYMOUS_STORE_KEY, true);

    useEffect(() => {
        window.addEventListener('message', handleMessage, false);
    }, []);

    const handleMessage = (event: any) => {
        switch (event.data) {
            case 'success-auth': {
                handleLogin();
                return;
            }
            case 'cancel-auth': {
                closeLoginDialog();
                return;
            }
            default: {
                return;
            }
        }
    };

    const handleLogin = () => {
        setLoadingState(true);
        setIframeState(false);
        const cookies = parseCookies(null);
        const token = joinTokenFromCookies(cookies);

        axios
            .get('/api/token', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                setToken(token);

                if (mc) {
                    makeGetOne(
                        TOKEN_WITH_ROLES,
                        {
                            id: composeUri(TOKEN_WITH_ROLES),
                        },
                        {
                            queryClient,
                            notify,
                            translate: tCommon,
                            errorMessage: 'errors.getTokenWithRoles',
                            onSuccess: ({ token }: any) => {
                                const {
                                    payload,
                                    payload: { roles, is_anonymous },
                                }: any = decodeToken(token);

                                const keys = Object.keys(roles);
                                let arrayRoles: string[] = [];
                                keys.map((key) => {
                                    arrayRoles.push(roles[key]);
                                });

                                setTokenWithRoles(token);
                                setTokenData(payload);
                                setRoles(arrayRoles);
                                setIsAnonymous(is_anonymous);
                                closeLoginDialog();
                            },
                        }
                    );
                }
            });
    };

    const setUrl = () => {
        const url = new URL(`${loginUrl}`);
        url.searchParams.append('root-address', rootAddress);
        setIframeUrl(url.href);
    };

    useEffect(() => {
        setUrl();
    }, []);

    return (
        iframeUrl && (
            <Dialog open={loginDialogState} fullScreen data-cy='loginDialog'>
                {iframeState && (
                    <iframe
                        src={iframeUrl}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            overflow: 'hidden',
                            zIndex: 999999,
                        }}
                        onLoad={() => setLoadingState(false)}
                    >
                        {`Your browser doesn't support iframes`}
                    </iframe>
                )}

                <LoadingBackdrop open={loadingState} />
            </Dialog>
        )
    );
};

export default withLoginHandler()(LoginDialog);
